<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'商品'"
      :isInline="true"
    >
      <div slot="toolSub"></div>
      <div class="search" slot="tool">
        <el-input
          placeholder="按岗位编码查询"
          v-model="searchQuery.jobCode"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">岗位编码</template>
        </el-input>

        <el-input
          placeholder="按岗位名称查询"
          v-model="searchQuery.jobName"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">岗位名称</template>
        </el-input>

        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">状态</div>
          <el-select
            filterable
            v-model="searchQuery.status"
            clearable
            placeholder="按状态筛选"
            size="small"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    // let self = this;
    return {
      remote: api.common,
      apiName: "/systemJob",
      searchVal: "",
      jobList: [],
      typeList: [
        {
          label: "启用",
          value: 0,
        },
        {
          label: "禁用",
          value: 1,
        },
      ],
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { jobName: "", jobCode: "", status: "" },
      rules: {
        //传递给APPlist的表单验证
        jobCode: [
          {
            required: true,
            message: "请输入岗位编码",
            trigger: ["blur", "change"],
          },
        ],
        jobName: [
          {
            required: true,
            message: "请输入岗位名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "编码",
          prop: "jobCode",
          align: "center",
        },
        {
          label: "名称",
          prop: "jobName",
          align: "center",
        },

        {
          label: "排序",
          prop: "seqNo",
          align: "center",
        },

        {
          label: "状态",
          prop: "status",
          align: "center",
          type: "colorText",
          formatter: function(row) {
            const statusMap = {
              0: { text: "启用", color: "#67C23A" },
              1: { text: "禁用", color: "#F56C6C" },
            };
            return statusMap[row.status];
          },
        },

        {
          label: "操作",
          align: "center",
          type: "operationLink",
          formatter: function(row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "edit",
                handler: function() {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "delete",
                handler: function() {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "岗位编码",
          prop: "jobCode",
          type: "input",
        },
        {
          label: "岗位名称",
          prop: "jobName",
          type: "input",
        },

        {
          label: "排序",
          prop: "seqNo",
          type: "input",
        },
        {
          label: "状态",
          prop: "status",
          type: "select",
          selectData: [
            {
              label: "启用",
              value: 0,
            },
            {
              label: "禁用",
              value: 1,
            },
          ],
        },
      ],
    };
  },
  created() {},
  methods: {
    //同步默认商品到商户
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
